import React, {memo} from 'react'
import { DndProvider } from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import NeteaSchedulerComponent from './NeteaScheduler'


export const NeteaScheduler = memo(props => {
    const {
        minTime,
        maxTime,
        resources,
        schedules,
        layoutColor,
        slotDuration,
        clickOnTimeSlot,
        fetchStart,
        fetchEnd,
        showNotification,
        moveSchedule,
        refresh,
        refreshRows,
        refreshSchedules,
    } = props;

    return (
        <DndProvider backend={HTML5Backend}>
            <NeteaSchedulerComponent
                minTime={minTime}
                maxTime={maxTime}
                resources={resources}
                schedules={schedules}
                layoutColor={layoutColor}
                slotDuration={slotDuration}
                clickOnTimeSlot={clickOnTimeSlot}
                fetchStart={fetchStart}
                fetchEnd={fetchEnd}
                showNotification={showNotification}
                moveSchedule={moveSchedule}
                refresh={refresh}
                refreshRows={refreshRows}
                refreshSchedules={refreshSchedules}
            />
        </DndProvider>
    )
});
