import React, {Component} from 'react'
import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interaction from '@fullcalendar/interaction';
import '@fullcalendar/core/main.css';
import '@fullcalendar/timeline/main.css';
import '@fullcalendar/resource-timeline/main.css';
import {fetchIt, getErrorMessage} from "../../../helpers";
import {
    GET_LIST,
    required,
    TextInput,
    SimpleForm,
    ReferenceInput,
    ReferenceArrayInput,
    SelectInput,
    SelectArrayInput,
    NumberInput,
    FormDataConsumer,
    Button,
    UPDATE,
    CREATE,
    DELETE
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import IconCancel from '@material-ui/icons/Cancel';
import IconEdit from '@material-ui/icons/Edit';
// import IconDelete from '@material-ui/icons/Delete';
import {Menu, Item, contextMenu, theme, animation} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import moment from "moment";
import {DateTimePicker} from '../../../common';
import {NtaSaveButton} from '../../common/index';
import DialogActions from '@material-ui/core/DialogActions';
import CalendarAutoComplete from "../../../components/CalendarAutoComplete";
import Tooltip from './Tooltip';
import {startsAtValidation, endsAtValidation, requiredPositiveNumberValidation} from '../../../validators';


const FormActions = props => {
    const {basePath, onCloseClick, isDisabled, showNotification, ...rest} = props;

    return (
        <DialogActions {...rest}>
            <NtaSaveButton label="Save" onCloseClick={onCloseClick} showNotification={showNotification} isDisabled={isDisabled} />
            <Button label="Cancel" onClick={onCloseClick}>
                <IconCancel />
            </Button>
        </DialogActions>
    );
};


export default class Calendar extends Component {
    _isMounted = false;
    _isJustMounted = true;
    calendarRef = React.createRef();


    constructor (props) {
        super(props);

        this.state = {
            defaultDate: moment().format(),
            studios: [],
            events: [],
            isLoading: true,
            showDialog: false,
            isDisabled: false,
            tooltip: false,
            event: {},
            tooltipPosition: {
                top: -500,
                left: -1000
            },
            tooltipWidth: 0,
            tooltipHeight: 0,
            slotWidth: 20,
            teachers: false
        };
    }


    componentDidMount() {
        this._isMounted = true;

        this.fetchTheData();
    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    eventMouseEnter = mouseEnterInfo => {
        const event = this.state.events.find(event => {
            return parseInt(event.id) === parseInt(mouseEnterInfo.event.id);
        });

        if (this._isMounted) {
            this.setState({
                tooltip: true,
                event
            });
        }

        mouseEnterInfo.el.onmousemove = e => {
            let coordX = e.pageX + 20;
            if ((window.innerWidth - e.pageX) < (this.state.tooltipWidth + 30)) {
                coordX = e.pageX - this.state.tooltipWidth - 20;
            }

            let coordY = e.pageY + 10;
            if ((window.innerHeight - e.pageY) < (this.state.tooltipHeight + 35)) {
                coordY = e.pageY - this.state.tooltipHeight - 20;
            }

            if (this._isMounted) {
                this.setState({
                    tooltipPosition: {
                        top: coordY,
                        // top: e.pageY + 10,
                        left: coordX
                    }
                });
            }
        };
    };

    eventDrop = eventDropInfo => {
        let event = this.state.events.find(event => {
            return parseInt(event.id) === parseInt(eventDropInfo.event.id);
        });

        if (eventDropInfo.newResource) {
            event.Studio = parseInt(eventDropInfo.newResource.id);
            event.resourceId = parseInt(eventDropInfo.newResource.id);
        }

        event.start = moment(eventDropInfo.event.start).format();
        event.end = moment(eventDropInfo.event.end).format();

        this.sendData(event, eventDropInfo);
    };

    eventResize = eventResizeInfo => {
        let event = this.state.events.find(event => {
            return parseInt(event.id) === parseInt(eventResizeInfo.event.id);
        });
        event.start = moment(eventResizeInfo.event.start).format();
        event.end = moment(eventResizeInfo.event.end).format();

        this.sendData(event, eventResizeInfo);
    };

    getTooltipWidth = width => {
        this.setState({tooltipWidth: width});
    };

    getTooltipHeight = height => {
        this.setState({tooltipHeight: height});
    };


    eventMouseLeave = mouseLeaveInfo => {
        mouseLeaveInfo.el.onmousemove = null;
        if (this._isMounted) {
            this.setState({
                tooltip: false,
                tooltipPosition: {
                    top: -500,
                    left: -1000
                }
            });
        }
    };


    eventRender = info => {
        if (info.event.extendedProps.IsEvent) {
            info.el.oncontextmenu = e => {
                e.preventDefault();

                contextMenu.show({
                    id: 'TheItemContextMenu',
                    event: e,
                    props: info
                });
            };
        }
    };


    fetchTheData = (Start, End) => {
        const {fetchStart, fetchEnd, showNotification} = this.props;
        const me = this;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let filter = {};
        if (Start && End) {
            filter = {
                Start,
                End
            };
        } else {
            const today = moment().format('YYYY-MM-DD');

            filter = {
                Start: moment(today).format(),
                End: moment(today).add(1, 'days').format()
            };
        }

        fetchIt(
            GET_LIST,
            'studios',
            {filter: {"IsActive": true}}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let studios = [];
                    // eslint-disable-next-line
                    for (const studio in response.data) {
                        if (response.data[studio].IsActive) {
                            const studioColor = response.data[studio].DisplayColor;
                            if (studioColor === '#FAFAFA') {
                                response.data[studio].DisplayColor = '#666666';
                            }

                            studios.push(
                                {
                                    id: response.data[studio].id,
                                    title: response.data[studio].Name,
                                    eventColor: response.data[studio].DisplayColor,
                                    studioColor: studioColor
                                }
                            )
                        }
                    }

                    fetchStart();

                    fetchIt(
                        GET_LIST,
                        'calendar',
                        {filter}
                    ).then(response => {
                        switch (response.status) {
                            case 200:
                                let events = [];

                                for (let t = 0; t < response.data.length; t++) {
                                    if (
                                        response.data[t] &&
                                        response.data[t].id &&
                                        response.data[t].StartDateTime &&
                                        response.data[t].EndDateTime
                                    ) {
                                        let event = response.data[t];

                                        event.resourceEditable = event.IsEvent;
                                        event.editable = event.IsEvent;
                                        event.resourceId = event.Studio;
                                        event.title = event.Name + ' ' + event.Availability;
                                        event.start = moment(event.StartDateTime, 'M/D/YYYY h:mm:ss A').format();
                                        event.end = moment(event.EndDateTime, 'M/D/YYYY h:mm:ss A').format();

                                        events.push(event);
                                    }
                                }

                                if (me._isMounted) {
                                    me.setState({
                                        studios,
                                        events,
                                        isLoading: false
                                    });
                                }

                                break;

                            default:
                                showNotification(
                                    'direct_message',
                                    'warning',
                                    { messageArgs: { _: 'Oops, something went wrong!' } }
                                );
                        }
                    }).catch(error => {

                    }).then(() => {
                        // Dispatch an action letting react-admin know an API call has ended
                        fetchEnd();
                    });

                    if (me._isMounted) {
                        me.setState({
                            studios,
                            isLoading: false
                        });
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
        });
    };


    datesRender = info => {
        let slotWidth = 20;
        if (info.view.type === 'resourceTimelineWeek') {
            slotWidth = 50;
        }

        if (this._isMounted) {
            this.setState({slotWidth});
        }

        if (!this._isJustMounted) {
            this.fetchTheData(moment(info.view.currentStart).format(), moment(info.view.currentEnd).format());
        } else {
            this._isJustMounted = false;
        }
    };

    onEditEventClick = e => {
        const event = this.state.events.find(event => {
            return parseInt(event.id) === parseInt(e.props.event.id);
        });

        if (this._isMounted) {
            this.setState({
                showDialog: true,
                event
            });
        }
    };

    onCloseClick = () => {
        this.setState({showDialog: false});
    };

    onNewEventClick = e => {
        this.setState({event: {}, showDialog: true});
    };

    onDeleteEventClick = e => {
        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        fetchIt(
            DELETE,
            'calendar',
            {id: e.props.event.id}
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Event deleted.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    let events = this.state.events;
                    events = events.filter(event => ((event.id + '') !== e.props.event.id));

                    this.setState({events});

                    break;

                case 400:
                    let errorMessage = 'Event can not be deleted.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    onSubmit = values => {
        if (this._isMounted) {
            this.setState({
                isDisabled: true
            });
        }

        if (this.state.event.id) {
            values.id = this.state.event.id;
        }

        this.sendData(values);
    };


    goToDate = newDate => {
        let calendarApi = this.calendarRef.current.getApi();
        calendarApi.gotoDate(newDate);
    };

    sendData = (data, eventInfo = null) => {
        const me = this;
        const {fetchStart, fetchEnd, showNotification} = this.props;

        // Dispatch an action letting react-admin know an API call is ongoing
        fetchStart();

        let requestType = CREATE;
        if (data.id) {
            requestType = UPDATE;
        }

        let dataToSend = Object.assign({}, data);

        dataToSend.StartDateTime = moment(data.start).format('YYYY-MM-DDTHH:mm');
        dataToSend.EndDateTime = moment(data.end).format('YYYY-MM-DDTHH:mm');

        delete(
            dataToSend.resourceEditable,
            dataToSend.editable,
            dataToSend.resourceId,
            dataToSend.title,
            dataToSend.start,
            dataToSend.end
        );

        fetchIt(
            requestType,
            'calendar',
            dataToSend
        ).then(response => {
            switch (response.status) {
                case 200:
                    let message = 'Event saved.';
                    if (response.data.Message) {
                        message = response.data.Message;
                    }

                    showNotification(
                        'direct_message',
                        'info',
                        { messageArgs: { _: message } }
                    );

                    const eventDate = moment(data.start).format('YYYY-MM-DD');
                    const eventDateObject = moment(eventDate);

                    if (me._isMounted) {
                        me.setState({
                            event: {},
                            defaultDate: eventDateObject.format()
                        });
                    }

                    this.goToDate(eventDate);

                    const viewType = me.calendarRef.current.getApi().view.type;
                    let viewStartDate = eventDateObject.format();
                    let viewEndDate = eventDateObject.add(1, 'days').format();

                    switch (viewType) {
                        case 'resourceTimelineWeek':
                            viewStartDate = eventDateObject.day('Sunday').format();
                            viewEndDate = eventDateObject.day('Sunday').add(7, 'days').format();

                            break;

                        case 'resourceTimelineMonth':
                            viewStartDate = eventDateObject.date(1).format();
                            viewEndDate = eventDateObject.date(1).add(1, 'month').format();

                            break;

                        default:
                            //
                    }

                    this.fetchTheData(viewStartDate, viewEndDate);

                    break;

                case 400:
                    let errorMessage = 'The form is NOT valid. Please check for errors.';
                    if (typeof response.data.HasValidationErrors !== 'undefined') {
                        errorMessage = getErrorMessage(response.data);
                    }

                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: errorMessage } }
                    );

                    if (eventInfo) {
                        eventInfo.revert();
                    }

                    break;

                default:
                    showNotification(
                        'direct_message',
                        'warning',
                        { messageArgs: { _: 'Oops, something went wrong!' } }
                    );
            }
        }).catch(error => {

        }).then(() => {
            // Dispatch an action letting react-admin know an API call has ended
            fetchEnd();
            if (me._isMounted) {
                me.setState({
                    isDisabled: false
                });
            }
        });
    };

    resourceRender = info => {
        info.el.style.color = info.resource.extendedProps.studioColor;
        if (info.resource.extendedProps.studioColor === '#FAFAFA') {
            info.el.style.backgroundColor = '#666666'
        }
    };


    render() {
        return (
            <div className="wrapperContent calendarWrapper">
                {
                    !this.state.isLoading ?
                        <FullCalendar
                            ref={this.calendarRef}
                            customButtons={
                                {
                                    newEvent: {
                                        text: 'Add New Event',
                                        click: this.onNewEventClick
                                    }
                                }
                            }
                            views={{
                                resourceTimelineWeek: { // name of view
                                titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' }
                            }
                            }}
                            defaultView="resourceTimelineDay"
                            plugins={[interaction, resourceTimelinePlugin]}
                            header={{
                                left: 'prev,today,next newEvent',
                                center: 'title',
                                right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth'
                            }}
                            editable={true}
                            resourceLabelText='Studios'
                            resources={this.state.studios}
                            events={this.state.events}
                            minTime='09:00:00'
                            maxTime='22:00:00'
                            defaultDate={this.state.defaultDate}
                            height='auto'
                            resourceAreaWidth={170}
                            slotDuration='00:05:00'
                            slotWidth={this.state.slotWidth}
                            schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
                            eventRender={this.eventRender}
                            datesRender={this.datesRender}
                            resourceRender={this.resourceRender}
                            eventMouseEnter={this.eventMouseEnter}
                            eventMouseLeave={this.eventMouseLeave}
                            eventDrop={this.eventDrop}
                            eventResize={this.eventResize}
                        />
                    : null
                }

                {
                    this.state.tooltip ?
                        <Tooltip
                            event={this.state.event}
                            resources={this.state.studios}
                            tooltipPosition={this.state.tooltipPosition}
                            getWidth={this.getTooltipWidth}
                            getHeight={this.getTooltipHeight}
                        />
                    : null
                }
                <Dialog
                    open={this.state.showDialog}
                    onClose={this.onCloseClick}
                    aria-label={this.state.event.hasOwnProperty('id') ? "Edit Event" : "Add New Event"}
                >
                    <DialogTitle style={{paddingBottom: 0}}>{this.state.event.hasOwnProperty('id') ? "Edit Event" : "Add New Event"}</DialogTitle>

                    <DialogContent>
                        <SimpleForm
                            resource={this.props.resource}
                            record={this.state.event}
                            save={this.onSubmit}
                            autoComplete='off'
                            toolbar={null}
                        >
                            <TextInput source="Name" className='fieldsWidthExpand' multiline validate={required('The Name field is required')}/>

                            <ReferenceInput
                                label='Studio'
                                source='Studio'
                                resource='studios'
                                reference='studios'
                                filter={{IsActive: true}}
                                perPage={1000}
                                validate={required('The Studio field is required')}
                            >
                                <SelectInput optionText='Name' style={{width: 300}} />
                            </ReferenceInput>

                            <FormDataConsumer>
                                {
                                    ({formData, ...rest}) => {
                                        return (
                                            <ReferenceInput
                                                label='Course'
                                                source='Course'
                                                reference='courses'
                                                filter={{ClassType: 2, IsActive: true}}
                                                perPage={1000}
                                                validate={required('The Course field is required')}
                                            >
                                                <CalendarAutoComplete
                                                    source='Course'
                                                    id='Course'
                                                    myVal={formData.Course}
                                                    optionRenderer={choice => choice ? `${choice.Name}` : ''}
                                                    validate={required('The Course field is required')}
                                                />
                                            </ReferenceInput>
                                        );
                                    }
                                }
                            </FormDataConsumer>

                            <DateTimePicker
                                source='start'
                                label='Starts at'
                                dateFormat='MM/DD/YYYY h:mm A'
                                validate={startsAtValidation}
                                disablePast
                                style={{width: 300}}
                            />

                            <DateTimePicker
                                source='end'
                                label='Ends at'
                                dateFormat='MM/DD/YYYY h:mm A'
                                validate={endsAtValidation}
                                disablePast
                            />

                            <RichTextInput source='Description' />

                            <ReferenceArrayInput
                                label='Teachers'
                                source='Teachers'
                                resource='teachers'
                                reference='teachers'
                                filter={{IsActive: true}}
                                perPage={1000}
                                className='fieldsWidthExpand'
                            >
                                <SelectArrayInput optionText='Name' style={{width: 350}}/>
                            </ReferenceArrayInput>

                            <NumberInput
                                source='Places'
                                validate={requiredPositiveNumberValidation}
                                className='fieldsWidthExpand'
                            />

                            <FormActions
                            style={{
                                backgroundColor: '#f5f5f5',
                                justifyContent: 'flex-start',
                                width: '100%'
                            }}
                            onCloseClick={this.onCloseClick}
                            showNotification={this.props.showNotification}
                            isDisabled={this.state.isDisabled}
                            />
                        </SimpleForm>
                    </DialogContent>
                </Dialog>

                <Menu
                    id='TheItemContextMenu'
                    theme={theme.light}
                    animation={animation.flip}
                    style={{position: 'fixed', zIndex: 111}}
                >
                    <Item onClick={this.onEditEventClick}>
                        <IconEdit/>
                        <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>Edit Event</div>
                    </Item>
                    {/*<Item onClick={this.onDeleteEventClick}>*/}
                    {/*    <IconDelete/>*/}
                    {/*    <div style={{display: 'inline-block', lineHeight: '24px', paddingLeft: 10}}>Remove Event</div>*/}
                    {/*</Item>*/}
                </Menu>
            </div>
        )
    }
}
