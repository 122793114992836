import React, { Fragment } from 'react';
import {
    TextField
} from 'react-admin';
import {DetailsLayout, DetailsTileWrapper} from '../../common';



export const HolidayShow = props => {
    const options = {
        detailsTitle: 'holiday',
        titleField: 'HolidayName', 
    };

    return (
        <Fragment >
            <DetailsLayout {...props} options={options}>
                <DetailsTileWrapper
                    sideTitle='Holiday Details'
                    sideText='Add your holiday here.'
                >
                    <TextField source='HolidayName' />
                    <TextField source='From' />
                    <TextField source='To' />
                </DetailsTileWrapper>
            </DetailsLayout>
        </Fragment>
    );
};
